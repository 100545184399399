import {
	FactoryHealthReport,
	StationHealthReport,
	EverythingReport,
	PersonalReport,
} from "./types";
import moment from "moment/moment";
import apiClient from "../ordertrack-client";
import dataToCsv from "../utils/data-to-csv";
import { PersonalReportParams } from "../../providers/StationReportsProvider/use-personal-reports";

export const getStationHealthReport = async (
	placeId: string | number,
	params?: {
		page?: number;
		start_date?: Date;
		end_date?: Date;
		per_page?: number;
	},
	signal?: AbortSignal
): Promise<StationHealthReport> => {
	const currentPage = params?.page ? params.page : 1;
	const res = await apiClient.get(`/locations/${placeId}/report`, {
		params: { ...params, page: currentPage },
		signal,
	});
	return res.data.data;
};

export const getFactoryHealthReport =
	async (): Promise<FactoryHealthReport> => {
		const res = await apiClient.get("/locations", {
			params: { _report: true },
		});
		return res.data.data;
	};

export const getEverythingReport = async (
	page = 1,
	signal?: AbortSignal
): Promise<EverythingReport> => {
	const res = await apiClient.get("/reports/everything_report", {
		params: { page },
		signal,
	});

	return res.data.data;
};
export const getPersonalReport = async (
	params?: PersonalReportParams
): Promise<PersonalReport> => {
	const res = await apiClient.get(`/users/report`, {
		params: { ...params },
	});

	return res.data;
};

export const getEverythingCsv = async (data: any) => {
	dataToCsv(data, `report-${moment().format("DD MMM, YYYY [at] hh:mma")}`);
};
