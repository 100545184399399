export default function EmbedDemo() {
	return (
		<iframe
			src="http://localhost:3001/quantity-report/1945"
			width="100%"
			height="100%"
			title="Quanity Report"
			allowFullScreen
		></iframe>
	);
}
