import { FormikHelpers } from "formik";
import { Dispatch, SetStateAction } from "react";
import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
	createStyles({
		grid_item: {
			display: "flex",
		},
		icon_checkbox_wrap: {
			position: "relative",
			minWidth: "80%",
			border: "1px solid rgba(0, 0, 0, 0.43)",
			borderRadius: "4px",
			margin: "0.8rem auto",
			"& .MuiCheckbox-root": {
				position: "absolute",
				top: 0,
			},
			"& .MuiFormControlLabel-label": {
				margin: "auto",
			},
		},
		is_icon_selected: {
			borderColor: "#ec1c24",
		},
		add_icon_btn: {
			textAlign: "center",
			width: "max-content",
			border: "1px dashed",
			display: "block",
			marginTop: "1.5rem",
			textTransform: "capitalize",
			padding: "1rem",
			minWidth: "115px",
		},
	});
export interface PlaceFormData {
	name?: string;
	width?: number | null;
	height?: number | null;
	depth?: number | null;
	unit?: "METER" | "FEET" | "INCHES" | null;
	parent_location_id?: number | null;
	logo_url?: string | null;
	public_access?: boolean;
}

export interface PlaceFormProps {
	initialValues?: PlaceFormData;
	disabled?: boolean;
	onSubmit?: (data: PlaceFormData) => void;
}

export type PlaceFormRef = FormikHelpers<PlaceFormData>;

export interface PlaceIconsDialogProps {
	title?: string;
	selectedIcon: string;
	setSelectedIcon: Dispatch<SetStateAction<string>>;
}

export interface PlaceIconsDialogApi {
	openDialog: () => void;
}
