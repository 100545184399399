import React from "react";
import { TemplateComponent } from "./types";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Image from "../_base/components/Image";
import TemplateParser from "../_base/components/TemplateParser";
import LoadingIndicator from "../_base/components/LoadingIndicator";
import MomenttrackLogo from "../_base/images/mtlogo.png";
import { useState, useMemo, useEffect } from "react";
import axios from "axios";
// TODO: This is temporary json as a sample. Replace it with API call when done.
import runlessWireTemplate from "./runlesswire.json";
import philipsTemplate from "./philips.json";

async function getTemplate(qrCode: string): Promise<TemplateComponent | null> {
	try {
		const res = await axios.get<TemplateComponent>(
			`${process.env.REACT_APP_LIFETIME_API_HOST}/${qrCode}/fetch/template`
		);
		return res.data;
	} catch (err) {
		switch (qrCode) {
			case "runlesswire":
				return runlessWireTemplate;
			default:
				if (qrCode.includes("1P912401592204")) return philipsTemplate;
				return null;
		}
	}
}

const ProductPage: React.FC = () => {
	const [template, setTemplate] = useState<TemplateComponent | null>(null);
	const [loading, setLoading] = useState(false);

	// Parse url query string for qr code and memorize.
	const qrCode = useMemo(() => {
		const urlQuery = window.location.search;
		const match = urlQuery.match(/qr=([^&]+)/i);

		if (!match) return null;

		return match[1];
	}, []);

	// Query database for template associated with qr code.
	useEffect(() => {
		if (!qrCode) return;

		(async () => {
			try {
				setLoading(true);
				// Query database for template associated with qr code.
				const template = await getTemplate(qrCode);
				setTemplate(template);
			} catch (err) {
				alert((err as Error).message);
			} finally {
				setLoading(false);
			}
		})();
	}, [qrCode]);

	return (
		<Container maxWidth="xs" disableGutters>
			{!!qrCode && !!template && (
				<TemplateParser
					onEdit={() => {}}
					onDelete={() => {}}
					qrcode={qrCode}
					template={template}
				/>
			)}
			{loading && <LoadingIndicator overlay fixed />}

			<Box
				px={2}
				py={1}
				textAlign="center"
				style={{
					backgroundColor: "#ffffff",
					backgroundImage:
						"repeating-linear-gradient(-45deg, rgba(255, 255, 255, 0) 0px, rgba(255, 255, 255, 0) 10px, rgba(0, 0, 0, 0.025) 10px, rgba(0, 0, 0, 0.025) 20px)",
				}}
			>
				<Typography component="div" variant="caption">
					<i>
						This QR Experience is brought to you by
						<a href="https://www.momenttrack.com" target="_blank" rel="noreferrer">
							<Image
								alt="Powered by momenttrack"
								src={MomenttrackLogo}
								style={{
									maxHeight: 16,
									verticalAlign: "middle",
									marginLeft: "8px",
								}}
							/>
						</a>
					</i>
				</Typography>
			</Box>
		</Container>
	);
};

export default ProductPage;
