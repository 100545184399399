import React from "react";
import { Box, Button, Card, CardHeader, makeStyles } from "@material-ui/core";
import { qtyStyles } from "../styles";
import { usePlaceReport } from "../../../core/providers/PlaceReportProvider";
import mtLogo from "../../../assets/images/mtlogo.png";
import ContentCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles(qtyStyles);

const QuantityReport: React.FC = () => {
	const classes = useStyles();

	const placeReport = usePlaceReport();

	const embedUrl = `${window.location.origin}/quantity-report/${
		placeReport?.location_id || placeReport?.id
	}`;
	const iframeCode = `<iframe src="${embedUrl}" width="100%" height="100%" frameborder="0" title="${placeReport?.name} Quantity Report" allowfullscreen></iframe>`;

	const copyToClipboard = () => {
		navigator.clipboard.writeText(iframeCode);
		alert("Embed code copied to clipboard!");
	};

	const qty = placeReport?.lp_qty;
	// Calculate dynamic font size based on the length of the quantity
	const baseFontSize = 25; // Maximum font size in rem
	const qtyLength = qty?.toLocaleString().length || 0;
	const dynamicFontSize = Math.min(baseFontSize, baseFontSize / (qtyLength / 4));

	return (
		<Card
			style={{
				padding: "1rem",
				background: "#a5cbf0",
				borderRadius: 8,
				margin: "1rem 0",
				maxWidth: "100%",
			}}
		>
			<Box className={classes.content}>
				{placeReport?.public_access && (
					<Button
						className={classes.embed_btn}
						startIcon={<ContentCopyIcon />}
						onClick={copyToClipboard}
					>
						Copy Embed Code
					</Button>
				)}

				<CardHeader style={{ fontSize: "2.5rem" }} title={`Quantity`} />
				<h1 className={classes.qty} style={{ fontSize: `${dynamicFontSize}rem` }}>
					{qty?.toLocaleString()}
				</h1>
				<p className={classes.name}>{placeReport?.name}</p>
			</Box>
			<Box className={classes.footer}>
				<div>
					<img
						src={`https://api.3hd.us/app/qrcode_gen.create?data=${
							process.env.REACT_APP_QR_BASENAME || "3hd.us"
						}/${placeReport?.beacon_id}`}
						alt=""
					/>
					<p style={{ margin: 0 }}>Details</p>
				</div>
				<Box>
					Powered By{" "}
					<img
						src={mtLogo}
						alt="Momenttrack trademark"
						style={{
							maxHeight: 16,
							verticalAlign: "middle",
							marginLeft: "8px",
						}}
					></img>
				</Box>
			</Box>
		</Card>
	);
};

export default QuantityReport;
